/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.button-native {
  border: 1px solid !important;
  box-shadow: 2px 3px #888888 !important;
}

.theme-btn {
  // background-color: var(--btn-color) !important;
  background-color:  #0C35FF !important;
  margin-left: auto;
  height: 42px;
  min-width: 60px;

  ion-label,
  ion-icon {
    color: #fff !important;
  }
}
.swiper-container-horizontal .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 20px 18px !important;
  background-color: var(--branding-white-color);
}