// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme((color: (primary: $app-primary,
        accent: $app-accent,
        warn: $app-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #0C35FF;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #0C9AFF;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #13D08E;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;



  --content-color: #EAECEE;
  --card-color: #fff;
  --font-color: #686868;
  --font-color-heading: #081645;
  --font-color-normal: #636466;
  --home-font-color: #000;
  // --home-font-color: red;
  --btn-color: #000;
  --shadow-color: #c6c6c3;

  // font sizes
  --font-size-small: 12px;
  --font-size-normal: 14px;
  --font-size-heading: 16px;
  --font-weight-heading: bold;




  // CREATE BRAND CUSTOM COLOR HERE

  --branding-primary-color: #0C35FF;
  --branding-secondary-color: #0C9AFF;
  --branding-default-color: #EEF3FF;
  --branding-black-color: #081645;
  --branding-white-color: #FFFFFF;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  // body {
  //   --ion-color-primary: #0C35FF;
  //   --ion-color-primary-rgb: 66, 140, 255;
  //   --ion-color-primary-contrast: #ffffff;
  //   --ion-color-primary-contrast-rgb: 255, 255, 255;
  //   --ion-color-primary-shade: #3a7be0;
  //   --ion-color-primary-tint: #5598ff;

  //   --ion-color-secondary: #0C9AFF;
  //   --ion-color-secondary-rgb: 80, 200, 255;
  //   --ion-color-secondary-contrast: #ffffff;
  //   --ion-color-secondary-contrast-rgb: 255, 255, 255;
  //   --ion-color-secondary-shade: #46b0e0;
  //   --ion-color-secondary-tint: #62ceff;

  //   --ion-color-tertiary: #6a64ff; 
  //   --ion-color-tertiary-rgb: 106, 100, 255;
  //   --ion-color-tertiary-contrast: #ffffff;
  //   --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  //   --ion-color-tertiary-shade: #5d58e0;
  //   --ion-color-tertiary-tint: #7974ff;

  //   --ion-color-success: #13D08E;
  //   --ion-color-success-rgb: 47, 223, 117;
  //   --ion-color-success-contrast: #000000;
  //   --ion-color-success-contrast-rgb: 0, 0, 0;
  //   --ion-color-success-shade: #29c467;
  //   --ion-color-success-tint: #44e283;

  //   --ion-color-warning: #ffd534;
  //   --ion-color-warning-rgb: 255, 213, 52;
  //   --ion-color-warning-contrast: #000000;
  //   --ion-color-warning-contrast-rgb: 0, 0, 0;
  //   --ion-color-warning-shade: #e0bb2e;
  //   --ion-color-warning-tint: #ffd948;

  //   --ion-color-danger: #ff4961;
  //   --ion-color-danger-rgb: 255, 73, 97;
  //   --ion-color-danger-contrast: #ffffff;
  //   --ion-color-danger-contrast-rgb: 255, 255, 255;
  //   --ion-color-danger-shade: #e04055;
  //   --ion-color-danger-tint: #ff5b71;

  //   --ion-color-dark: #f4f5f8;
  //   --ion-color-dark-rgb: 244, 245, 248;
  //   --ion-color-dark-contrast: #000000;
  //   --ion-color-dark-contrast-rgb: 0, 0, 0;
  //   --ion-color-dark-shade: #d7d8da;
  //   --ion-color-dark-tint: #f5f6f9;

  //   --ion-color-medium: #989aa2;
  //   --ion-color-medium-rgb: 152, 154, 162;
  //   --ion-color-medium-contrast: #000000;
  //   --ion-color-medium-contrast-rgb: 0, 0, 0;
  //   --ion-color-medium-shade: #86888f;
  //   --ion-color-medium-tint: #a2a4ab;

  //   --ion-color-light: #222428;
  //   --ion-color-light-rgb: 34, 36, 40;
  //   --ion-color-light-contrast: #ffffff;
  //   --ion-color-light-contrast-rgb: 255, 255, 255;
  //   --ion-color-light-shade: #1e2023;
  //   --ion-color-light-tint: #383a3e;
  // }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  // .ios body {
  //   --ion-background-color: #000000;
  //   --ion-background-color-rgb: 0, 0, 0;

  //   --ion-text-color: #ffffff;
  //   --ion-text-color-rgb: 255, 255, 255;

  //   --ion-color-step-50: #0d0d0d;
  //   --ion-color-step-100: #1a1a1a;
  //   --ion-color-step-150: #262626;
  //   --ion-color-step-200: #333333;
  //   --ion-color-step-250: #404040;
  //   --ion-color-step-300: #4d4d4d;
  //   --ion-color-step-350: #595959;
  //   --ion-color-step-400: #666666;
  //   --ion-color-step-450: #737373;
  //   --ion-color-step-500: #808080;
  //   --ion-color-step-550: #8c8c8c;
  //   --ion-color-step-600: #999999;
  //   --ion-color-step-650: #a6a6a6;
  //   --ion-color-step-700: #b3b3b3;
  //   --ion-color-step-750: #bfbfbf;
  //   --ion-color-step-800: #cccccc;
  //   --ion-color-step-850: #d9d9d9;
  //   --ion-color-step-900: #e6e6e6;
  //   --ion-color-step-950: #f2f2f2;

  //   --ion-item-background: #000000;

  //   --ion-card-background: #1c1c1d;
  // }

  // .ios ion-modal {
  //   --ion-background-color: var(--ion-color-step-100);
  //   --ion-toolbar-background: var(--ion-color-step-150);
  //   --ion-toolbar-border-color: var(--ion-color-step-250);
  // }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  // .md body {
  //   --ion-background-color: #121212;
  //   --ion-background-color-rgb: 18, 18, 18;

  //   --ion-text-color: #ffffff;
  //   --ion-text-color-rgb: 255, 255, 255;

  //   --ion-border-color: #222222;

  //   --ion-color-step-50: #1e1e1e;
  //   --ion-color-step-100: #2a2a2a;
  //   --ion-color-step-150: #363636;
  //   --ion-color-step-200: #414141;
  //   --ion-color-step-250: #4d4d4d;
  //   --ion-color-step-300: #595959;
  //   --ion-color-step-350: #656565;
  //   --ion-color-step-400: #717171;
  //   --ion-color-step-450: #7d7d7d;
  //   --ion-color-step-500: #898989;
  //   --ion-color-step-550: #949494;
  //   --ion-color-step-600: #a0a0a0;
  //   --ion-color-step-650: #acacac;
  //   --ion-color-step-700: #b8b8b8;
  //   --ion-color-step-750: #c4c4c4;
  //   --ion-color-step-800: #d0d0d0;
  //   --ion-color-step-850: #dbdbdb;
  //   --ion-color-step-900: #e7e7e7;
  //   --ion-color-step-950: #f3f3f3;

  //   --ion-item-background: #1e1e1e;

  //   --ion-toolbar-background: #1f1f1f;

  //   --ion-tab-bar-background: #1f1f1f;

  //   --ion-card-background: #1e1e1e;
  // }

}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

* {
  font-family: 'Poppins', Verdana;
}

@font-face {
  font-family: 'Poppins';
  src: url("../assets/fonts/Poppins-Regular.ttf");
}

.inner-scroll {
  padding: 0 30px !important;
}

.in-div {
  max-width: 1200px !important;
  margin: 0 auto !important;
}

ion-content {
  --background: #EEF3FF;
}

ion-card {
  --background: var(--card-color) !important;
}

.card-color {
  background: #ffffff !important;
  height: fit-content;
  margin-top: 122px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.chat-card-color {
  background: #ffffff !important;
  height: fit-content;
  // margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.custam-button {
  // --border-color: #c7c6c6;Poppins
  --border-width: 0px;
  font-size: 14px !important;
  // font-weight: bold;
  // box-shadow: 2px 4px #c6c6c6;
  box-shadow: 1px 1px 5px var(--shadow-color);
  border-radius: 4px;
  background: aliceblue;
  text-transform: none;

  ion-icon {
    margin-right: 5px;
  }
}

ion-card {
  // box-shadow: none;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
  // box-shadow: 1px 1px 5px var(--shadow-color);
}

.default-font-color {
  color: var(--font-color);
  letter-spacing: 1px;
  line-height: 23px;
}

.default-home-font-color {
  color: var(--home-font-color);
  font-weight: 300;
}

ion-icon {
  color: var(--font-color);
}


@media screen and (min-width: 575px) {
  ion-tab-bar {
    display: none !important;
  }
}

@media screen and (max-width: 575px) {
  app-webmenu {
    display: none !important;
  }
}

.tostError {
  .toast-content {
    --background: red !important;
  }
}

.tostValid {
  .toast-content {
    --background: #13D08E !important;
  }
}

.inner-scroll {
  --padding-start: 0px !important;
  --padding-end: 0px !important;
  --padding-top: var(--ion-padding, 16px) !important;
  --padding-bottom: var(--ion-padding, 16px) !important;
}

@media screen and (min-width: 575px) {
  .mat-row {
    height: 35px !important;
  }

  .mat-header-row {
    height: 40px !important;
  }

  .mat-header-cell {
    background-color: var(--font-color) !important;
  }

}

.fullScreen {
  --height: auto;
  --width: 370px;
}

.manageJobScreen {
  // --width: 80%;
  --height: 50%;
}

.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

.thankYou {
  --height: 525px !important;
  --width: 500px;
}

.companyForm {
  --height: 380px !important;
  --width: 500px;
}

.newThank {
  --height: 310px !important;
  --width: 500px;
}

.aboutYou {
  --height: 470px !important;
  --width: 800px;
}

.aboutYouProfile {
  --height: 61% !important;
  --width: 785px;
}

.aboutExp {
  --height: 515px !important;
  --width: 785px;
}
.aboutExpStream {
  // --height: 545px !important;
  --height: 575px !important;
  --width: 660px;
}
.aboutSkill {
  // --height: 100% !important;
  // --height: 450px !important;
  --height: 450px !important;
  --width: 785px;
}

.addEditEdu {
  --height: 63% !important;
  --width: 785px;
}

.docWalletClass {
  --height: 515px !important;
  --width: 500px;
}

.cms {
  --height: 100% !important;
  --width: 785px;
}

input[type=date],
input[type=datetime-local],
input[type=month],
input[type=time] {
  -webkit-appearance: listbox;
  margin-right: 14px;
}

@media screen and (max-width: 575px) {

  .aboutSkill,
  .aboutExp,
  .addEditEdu,
  .aboutYouProfile,
  .companyForm,
  .manageJobScreen,
  .contactUs,
  .aboutYou,
  .newThank,
  .thankYou,
  .docWalletClass {
    --height: 100% !important;
    --width: 785px;
  }
}

.data-table-mat {
  table {
    width: 100%;
  }

  .top {
    margin-top: 5px;
  }

  th {
    background-color: #3266c2;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
  }

  td.mat-column-star {
    width: 20px;
    padding-right: 8px !important;
  }

  .mat-row {
    &:hover {
      background-color: #e9f3ff;
    }

    a {
      color: #3e75ff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .mat-form-field {
    font-size: 14px;
  }

  .mat-icon {
    color: #3e75ff;
  }

  // @media screen and (max-width: 960px) {
  @media screen and (max-width: 575px) {
    .top {
      margin-top: 8px;
    }

    .mat-elevation-z8 {
      background: transparent;
      box-shadow: none;
    }

    .mat-header-row {
      display: none;
    }

    tbody {
      display: block;
      width: 100%;
    }

    .mat-table {
      background: transparent;

      * {
        box-sizing: border-box;
      }

      .mat-row {
        display: block;
        overflow: hidden;
        height: auto;
        position: relative;
        clear: both;
        box-shadow: 0 4px 15px -1px rgba(0, 0, 0, .2), 0 1px 12px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
        background-color: #fff;
        border-radius: 3px;

        +.mat-row {
          margin-top: 24px;
        }
      }

      .mat-cell {
        display: block;
        width: 100%;
        padding: 0 16px;
        margin: 16px 0;
        border: 0 none;
        text-align: left;
        // justify-content: flex-end;

        &:first-child {
          padding: 0 48px 0 16px;

          a {
            font-size: 20px;
            // font-weight: 400;
            color: inherit;
          }

          &:before {
            display: none;
          }
        }

        &.m-card-sub-title {
          margin-top: -8px;
          padding: 0 48px 0 16px;
          color: rgba(0, 0, 0, .54);
        }
      }

      .has_label_on_mobile {
        &:before {
          content: attr(data-label);
          display: inline;
          font-weight: normal;
        }
      }

      .mat-column-star {
        width: auto;
        padding: 8px 0 0 !important;
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;

        &:before {
          display: none;
        }
      }
    }

    .mat-paginator {
      margin-top: 24px;
    }

  }


  .search-filter {
    display: table-cell;
    margin-top: 5px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .manage-jobs-head {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .mat-card>:first-child,
  .mat-card-content>:first-child {
    margin-top: -3px;
  }

  .jobIcon {
    border-radius: 50%;
  }

  .noData {
    display: flow-root;
    padding: 15px;
    font-size: x-large;
    text-align: center;
  }

  ion-card-header+.card-content-md {
    padding-top: 0;
    padding-bottom: 30px;
  }

  .subtitle {
    font-size: 18px;
  }

  .noData {
    display: flow-root;
    padding: 15px;
    font-size: x-large;
    text-align: center;
  }

  ion-card-header+.card-content-md {
    padding-top: 0;
    padding-bottom: 30px;
  }

  .subtitle {
    font-size: 18px;
  }
}

.tr.mat-row {
  height: 10px !important;
}

.no-approve {
  .alert-message {
    color: red !important;
    font-size: 16px !important;
    font-weight: normal !important;
  }

}
.not-approve {
  backdrop-filter: blur(1px);
  .alert-message {
    color: red !important;
    font-size: 16px !important;
    font-weight: normal !important;
  }

}

.pprove {
  .alert-message {
    color: red !important;
    font-size: 13px !important;
    font-weight: normal !important;
  }

  .alert-wrapper {
    --max-width: 500px !important;
    --width: 500px !important;

  }

}

.alertCtrl {
  .alert-message {
    font-size: 16px !important;
    font-weight: normal !important;
  }
}

// .gm-ui-hover-effect{
//   border: 1px solid #fff !important;
// }

// .gm-ui-hover-effect:hover{
//   border: 1px solid red !important;
// }

.default-chip {
  height: 42px !important;
  font-size: 12px !important;
  margin-top: 8px !important;
  border-radius: 4px !important;
}

.sign-up-type {
  .alert-wrapper {
    --max-width: 348px !important;
    --width: 348px !important;

  }
}

.message-alert {
  .alert-wrapper {
    max-width: 500px;
    max-height: 300px;
  }
}

.my-custom-class {
  .alert-wrapper {
    max-width: 500px;
    max-height: 350px;
  }

  .alert-sub-title {
    // color: red !important;
    font-size: 22px;
    font-weight: 400;
    color: black;
  }

  .alertButton {
    // background-color: red;
    color: red;
    font-weight: 500 !important;
  }
}


.alert-class {
  .alert-wrapper {
    --min-width: 500px;
    --max-width: 400px;
    // --width: 800px;
  }

  .alert-message {
    color: red !important;
    font-size: 16px !important;
    font-weight: normal !important;
  }
}


.card-col {
  margin-top: 10px;
}

ion-input,
ion-select {
  height: 44px !important;
  color: #454545;
}

.my-modal {
  --height: auto !important;
  --width: 50rem !important;
}

.slider-modal {
  --height: 450px !important;
  --width: 70% !important;
}

@media screen and (min-width: 769px) and (max-width: 850px) {

  .my-modal {
    --height: auto !important;
    --width: 50rem !important;
  }

  // .slider-modal {
  //   --height: 50%;
  //   --width: auto !important;
  // }

  .swiper-pagination-bullets {
    display: none !important;
  }
}

@media screen and (max-width: 575px) {
  .my-modal {
    --height: auto !important;
    --width: 50rem !important;
  }

  .slider-modal {
    --height: 355px !important;
    --width: 70% !important;
  }

  .swiper-pagination-bullets {
    display: none !important;
  }

  .my-custom-class {
    .alert-wrapper {
      max-width: 350px;
      max-height: 300px;
    }

  }

  .alert-class {
    .alert-wrapper {
      --min-width: 350px;
      --max-width: 400px;
      // --width: 800px;
    }

  }


}

@media screen and (min-width: 576px) and (max-width: 769px) {
  .my-modal {
    --height: auto !important;
    --width: 50rem !important;
  }

  // .slider-modal {
  //   --height: 50%;
  //   --width: auto !important;
  // }

  .swiper-pagination-bullets {
    display: none !important;
  }
}

.cms-list {
  height: 250px;
  overflow: auto;
}

.notification-list {
  // height: 300px;
  max-height: 300px;
  min-height: 20px !important;
  height: auto !important;
  min-width: 100px !important;
  overflow: auto;
}

.left-align-buttons .action-sheet-button-inner.sc-ion-action-sheet-ios {
  justify-content: flex-start;
  font-size: 14px;
}

.native-input.sc-ion-input-md {
  color: gray;
}

.searchbar-input-container.sc-ion-searchbar-ios {
  font-size: 12px;
  color: rgb(87, 84, 84);
}

.typing {
  height: 50px !important;

  ion-textarea {
    align-self: end !important;
  }
}

.user-video {
  --height: auto;
  --width: 70%;

}